<template>
<!--  此页面已经废弃  2020-06-13 闫帅标注-->
  <el-row class="table">
    <span class="title">排课详情</span>
    <el-row class="box-line my-lesson">
      课程目录
      <el-row class="lesson-num">
        <span>
          总课次：
          <font>{{classNum}}</font>
        </span>
        <span>
          总任务数：
          <font>{{taskNum}}</font>
        </span>
      </el-row>
      <el-collapse
        :key="topindex"
        @change="handleChange"
        accordion
        v-for="(item, topindex) in taskData"
        v-model="activeNames"
      >
        <el-collapse-item :name="topindex">
          <template slot="title">
            第{{((pagination.currentPage -1 ) * 10) + Number(topindex + 1)}}课：{{item.title}}
            <el-button
              @click.stop="missedLessonManage(taskData[topindex])"
              plain
              size="small"
              style="margin-left:20px;"
              type="primary"
            >查询课件</el-button>
          </template>
          <el-row :reverse="reverse" style="padding-left: 20px;">
            <el-table
              :data="taskList"
              @selection-change="handleTaskSelection"
              tooltip-effect="dark"
            >
              <el-table-column align="center" type="selection" width="55"></el-table-column>
              <el-table-column align="center" label="序号" width="120">
                <template slot-scope="scope">{{ scope.$index + 1 }}</template>
              </el-table-column>
              <el-table-column align="center" label="任务标题" width="200">
                <template slot-scope="scope">
                  <span @click="previewTask(scope.row)" class="title-link">{{scope.row.title}}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="类型" width="120">
                <template slot-scope="scope">
                  <span class="title-link">{{scope.row.type}}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作学员" width="120">
                <template slot-scope="scope">
                  <el-popover
                    @hide="hideMethod"
                    class="openquestion"
                    placement="left"
                    trigger="click"
                    width="238"
                  >
                    <ul class="stuList clearfix">
                      <li>
                        <span class="stu-tit">全体</span>
                        <el-switch
                          @change="switchCheckAll(scope.row,switchAll,scope.$index)"
                          class="stu-switch"
                          v-model="switchAll"
                        ></el-switch>
                      </li>
                      <li :key="index" v-for="(value, index) of scope.row.taskUserList">
                        <span class="stu-tit">{{value.name}}</span>

                        <el-switch
                          :active-value="1"
                          :inactive-value="2"
                          @change="switchCheckItem(value,scope.$index,index)"
                          class="stu-switch"
                          v-model="value.status === '已启用'?1:2"
                        ></el-switch>
                        <!--                          @change="switchCheck(scope.row, item.userId, item.is_check,index)"-->
                      </li>
                    </ul>
                    <el-button
                      plain
                      size="small"
                      slot="reference"
                      style="margin-left: 10px;margin-top: 5px;"
                      type="primary"
                    >开放学员</el-button>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作" width="120">
                <template slot-scope="scope">
                  <el-popover
                    @hide="hideMethod"
                    class="previewquestion"
                    placement="left"
                    trigger="click"
                    v-if="scope.row.type === '编程'"
                    width="238"
                  >
                    <ul class="stuList clearfix">
                      <li :key="index" v-for="(value, index) of questionArray">
                        <span class="stupreview-tit">{{value.start_time | dateFormat}}</span>

                        <el-button
                          @click.stop="queryItem(value,scope.$index)"
                          class="stu-switch"
                          size="small"
                          type="primary"
                        >查看</el-button>
                      </li>
                    </ul>
                    <el-button
                      @click.stop="queryQuestion(scope.$index)"
                      plain
                      size="small"
                      slot="reference"
                      style="margin-left: 10px;margin-top: 5px;"
                      type="primary"
                    >查看题库</el-button>
                  </el-popover>
                  <el-popover
                    class="previewquestion"
                    placement="left"
                    trigger="click"
                    v-if="scope.row.type === '测试'"
                    width="238"
                  >
                    <ul class="stuList clearfix">
                      <li :key="index" v-for="(value, index) of questionArray">
                        <span class="stupreview-tit">{{value.start_time | dateFormat}}</span>

                        <el-button
                          @click.stop="openmarkingDialog(value.id,scope.row)"
                          class="stu-switch"
                          size="small"
                          type="primary"
                        >查看</el-button>
                        <!--@change="switchCheck(scope.row, item.userId, item.is_check,index)"-->
                      </li>
                    </ul>
                    <el-button
                      @click.stop="queryQuestion(scope.$index)"
                      plain
                      size="small"
                      slot="reference"
                      style="margin-left: 10px;margin-top: 5px;"
                      type="primary"
                    >审阅试卷</el-button>
                  </el-popover>
                  <el-popover
                    @hide="hideMethod"
                    class="previewquestion"
                    placement="left"
                    trigger="click"
                    v-if="scope.row.type ==='python'"
                    width="238"
                  >
                    <!-- <div>请选择需要查看的排课</div> -->
                    <ul class="stuList clearfix">
                      <li :key="index" v-for="(value, index) of lessonList">
                        <span class="stupreview-tit">{{value.start_time | formatTime}}</span>

                        <el-button
                          @click="submitListManage(scope.row,value.id)"
                          class="stu-switch"
                          size="small"
                          type="primary"
                        >查看</el-button>
                      </li>
                    </ul>
                    <el-button
                      plain
                      size="small"
                      slot="reference"
                      style="margin-left: 20px;margin-top: 5px;"
                      type="primary"
                    >查看提交</el-button>
                  </el-popover>
                  <el-popover
                    @hide="hideMethod"
                    class="previewquestion"
                    placement="left"
                    trigger="click"
                    v-if="scope.row.type ==='python'"
                    width="238"
                  >
                    <!-- <div>请选择需要查看的排课</div> -->
                    <ul class="stuList clearfix">
                      <li :key="index" v-for="(value, index) of lessonList">
                        <span class="stupreview-tit">{{value.start_time | formatTime}}</span>

                        <el-button
                          @click="submitListManage(scope.row,value.id)"
                          class="stu-switch"
                          size="small"
                          type="primary"
                        >查看</el-button>
                      </li>
                    </ul>
                    <el-button
                      plain
                      size="small"
                      slot="reference"
                      style="margin-left: 20px;margin-top: 5px;"
                      type="primary"
                    >查看提交</el-button>
                  </el-popover>

                </template>
              </el-table-column>
            </el-table>
            <el-popover placement="right" ref="popover4" trigger="click" width="400">
              <el-table
                :data="taskUserList"
                @selection-change="handleSelection"
                ref="multipleTable"
                style="width: 100%;height: 350px;overflow-y: auto"
                tooltip-effect="dark"
              >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column label="学号" prop="loginName" width="120"></el-table-column>
                <el-table-column label="姓名" prop="name" show-overflow-tooltip></el-table-column>
              </el-table>
              <el-button @click="mapTaskAndUser('已启用')" style="margin-top: 10px;">全部开放</el-button>
              <el-button @click="mapTaskAndUser('已禁用')" style="margin-top: 10px;">全部关闭</el-button>
              <el-button size="medium" slot="reference" style="margin-top: 20px;" type="info">批量开放</el-button>
            </el-popover>
          </el-row>
        </el-collapse-item>
      </el-collapse>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>
      <!-- 课件补课视频 dialog -->
      <missed-lesson
        :applyCourseData="missLessonCourseData"
        :show="missLessonCourseFlag"
        @close="missLessonCourseFlag=false"
      ></missed-lesson>
      <priview-file
        :show="priviewFileDialog"
        @close="priviewFileDialog=false"
      ></priview-file>
      <!-- 审批试卷 -->
      <marking-exampaper
        :class_id="class_id"
        :lessonId="lessonId"
        :show="markingDialog"
        :taskID="taskID"
        @close="markingDialog=false"
      ></marking-exampaper>
    </el-row>
  </el-row>
</template>

<script>
import {
  mapTaskAndUser,
  getInfo,
  listOnly,
  switchCheckItem,
  switchCheckAll,
  queryQuestion,
  getTaskCount
} from "@/api/senate/class";
import MissedLesson from "@/components/senate/missed-lesson";
import PreviewFile from "@/components/senate/preview-file";
import MarkingExampaper from "@/components/senate/marking-exampaper";

export default {
  name: "PreviewCourse",
  components: { MissedLesson, MarkingExampaper,PreviewFile },
  data() {
    return {
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0
      },
      className: "",
      dataList: [],
      classNum: 0,
      taskNum: 0,
      taskData: [],
      switchAll: false,
      missLessonCourseFlag: false,
      priviewFileDialog:false,
      missLessonCourseData: {},
      markingDialog: false,
      taskID: "0",
      lessonId: "",
      questionArray: [],
      activeNames: [],
      reverse: false,
      taskList: [],
      lessonList: [],
      taskIdArray: [],
      taskUserList: [],
      userChooseArray: [],
      currentConunitId: 0
    };
  },
  created() {
    this.class_id = this.$route.query.classID;
    this.className = this.$route.query.className;
    // this.getCourseUnitList();
    this.listOnly();
    if (this.$route.query.courseId) {
      this.getTaskCount();
    }
  },
  filters: {
    formatTime: function(value) {
      return value.substring(0, value.length - 2);
    }
  },
  methods: {
    priviewFile(row) {
      // this.missLessonCourseData = {};
      // this.missLessonCourseData = row;
      // this.missLessonCourseData.enterType = "teacher";
      this.priviewFileDialog = true
    },
    getTaskCount() {
      getTaskCount({
        courseId: this.$route.query.courseId
      }).then(res => {
        if (res.state === "success") {
          // console.log('res', res)
          this.taskNum = res.body;
        }
      });
    },
    goBack() {
      this.$router.back(-1);
    },
    handleTaskSelection(sels) {
      this.taskIdArray = [];
      for (let i = 0; i < sels.length; i++) {
        this.taskIdArray.push(sels[i].id);
      }
    },
    handleSelection(sels) {
      this.userChooseArray = [];
      for (let i = 0; i < sels.length; i++) {
        this.userChooseArray.push(sels[i].userId);
      }
    },
    handleChange(val) {
      if (val === "") {
        return false;
      }
      this.currentConunitId = this.taskData[val].id;
      this.courseUnitList(this.taskData[val].id);
    },
    // previewTask(row) {
    //   this.$router.push({
    //     path: "/research/preview-task",
    //     query: {
    //       taskID: row.id
    //     }
    //   });
    // },
    // 分页
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.listOnly();
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.listOnly();
    },
    hideMethod() {
      this.switchAll = false;
      // this.getCourseUnitList();
    },
    submitListManage(row, lessonId) {
      this.$router.push({
        path: "/senate/submit_list",
        query: {
          taskId: row.id,
          lessonId: lessonId,
          type: "pythontask"
        }
      });
    },
    missedLessonManage(row) {
      this.missLessonCourseData = {};
      this.missLessonCourseData = row;
      this.missLessonCourseData.enterType = "teacher";
      this.missLessonCourseFlag = true;
    },
    async listOnly() {
      const res = await listOnly({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        status: '已启用',
        courseId: "",
        classId: this.$route.query.classID
      });
      if (res.state === "success") {
        this.taskData = [];
        this.classNum = 0;
        this.pagination.total = res.body.total;
        this.taskData = res.body.list;
        this.classNum = res.body.total;
        // for (let i = 0; i < this.taskData.length; i++) {
        //   this.taskNum = this.taskNum + this.taskData[i].taskCount
        // }
      }
    },
    async courseUnitList(courseId) {
      const res = await getInfo({
        id: courseId,
        classId: this.$route.query.classID,
        onlyEnable: true
      });
      if (res.state === "success") {
        this.taskList = [];
        this.lessonList = [];
        this.taskList = res.body.taskList;
        this.taskUserList = this.taskList[0].taskUserList;
        this.lessonList = res.body.lessonList;
      }
    },
    async mapTaskAndUser(state) {
      if (this.taskIdArray.length == 0) {
        $msg("请先选择要开放的任务", 2);
        return false;
      } else if (this.userChooseArray.length == 0) {
        $msg("请先选择要开放的学生", 2);
        return false;
      }
      console.log("taskIdArray" + JSON.stringify(this.taskIdArray));
      console.log("userChooseArray" + JSON.stringify(this.userChooseArray));
      const res = await mapTaskAndUser({
        userIds: this.userChooseArray,
        taskIds: this.taskIdArray,
        classId: this.$route.query.classID,
        status: state
      });
      if (res.state === "success") {
        if (state === "已启用") {
          $msg("开放学员成功");
        } else if (state === "已禁用") {
          $msg("关闭学员成功");
        }
        this.courseUnitList(this.currentConunitId);
      }
    },

    async switchCheckAll(row, status, parentIndex) {
      if (row.taskUserList.length === 0) {
        return false;
      }
      var userIdArray = [];
      for (let i = 0; i < row.taskUserList.length; i++) {
        userIdArray.push(row.taskUserList[i].userId);
      }
      if (!status) {
        status = "已禁用";
      } else {
        status = "已启用";
      }
      const res = await switchCheckAll({
        taskId: row.taskUserList[0].taskId,
        userIds: userIdArray,
        status: status
      });
      if (res.state === "success") {
        if (status === "已禁用") {
          for (
            let i = 0;
            i < this.taskList[parentIndex].taskUserList.length;
            i++
          ) {
            this.taskList[parentIndex].taskUserList[i].status = "已禁用";
          }
        } else {
          for (
            let i = 0;
            i < this.taskList[parentIndex].taskUserList.length;
            i++
          ) {
            this.taskList[parentIndex].taskUserList[i].status = "已启用";
          }
        }
      }
    },

    async switchCheckItem(item, parentIndex, childindex) {
      var status = 0;
      var userIdArray = [];
      userIdArray.push(item.userId);
      if (item.status === "已启用") {
        status = "已禁用";
      } else {
        status = "已启用";
      }
      const res = await switchCheckItem({
        taskId: item.taskId,
        userIds: userIdArray,
        status: status
      });
      if (res.state === "success") {
        if (item.status === "已启用") {
          this.taskList[parentIndex].taskUserList[childindex].status = "已禁用";
        } else {
          this.taskList[parentIndex].taskUserList[childindex].status = "已启用";
        }
      }
    },

    queryItem (item, parentIndex) {
      var row = {}
      row.lesson_id = item.id
      row.class_id = this.class_id
      row.contest_id = this.taskList[parentIndex].sourceId
      row.className = ''
      // 跳转到题库
      this.$router.push({
        path: '/senate/class-quesbank-detail',
        query: {
          activeName: 'first',
          classData: JSON.stringify(row)
        }
      })
    },

    async queryQuestion (parentIndex) {
      const res = await queryQuestion({
        classId: this.class_id,
        taskId: this.taskList[parentIndex].id
      });
      if (res.state === 'success') {
        this.questionArray = res.body
      }
    },

    close () {
      this.markingDialog = false
      // this.dialogLesson = false;
      // this.addlessonData = null;
    },
    openmarkingDialog (lessonId, data) {
      this.lessonId = lessonId
      this.taskID = data.id
      this.markingDialog = true
    },

    // 预览任务
    previewTask (activity) {
      // console.log('activity', activity)
      let routeData = this.$router.resolve({
        path: '/research/preview-task',
        query: {
          classID: this.$route.query.classID,
          taskID: activity.id,
          tab: this.$route.query.tab
        }
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";
.table {
  padding-left: 20px;
}
.stuList {
  display: flex;
  flex-direction: column;
  height: 300px;
  margin-top: 10px;
  overflow-y: auto;
  li {
    margin-top: 8px;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .stu-tit {
    display: inline-block;
    padding-left: 10px;
    justify-content: flex-start;
    cursor: pointer;
  }

  .stu-switch {
    float: right;
  }
}
.list-group-item {
  width: 20%;
  padding: 10px;
  margin-top: 10px;
  border: solid 1px #f5c319;
}
.stupreview-tit {
  display: inline-block;
  padding-left: 10px;
  margin-top: 8px;
  justify-content: flex-start;
}
.previewquestion {
  position: absolute;
  top: 0;
  right: 25%;
  z-index: 9;
}
.openquestion {
  position: absolute;
  top: 0;
  right: 5%;
  z-index: 9;
}
.opensubmithis {
  position: absolute;
  top: 0;
  right: 35%;
  z-index: 9;
}
.parentitem {
  height: 50px;
  // cursor: pointer;
}
.task-state {
  position: absolute;
  top: 0;
  right: 15%;
  z-index: 9;
  margin-top: 10px;
  color: #30a766;
}
.box-line {
  background: #fff;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #aaaaaa;
  margin-top: 20px;
}
.my-lesson {
  .lesson-num {
    margin-top: 25px;
    span {
      &:first-child {
        margin-right: 15px;
      }
    }
  }
}
.container {
  // position: fixed;
  // left: 0;
  // top: 0;
  width: 100%;
  background-color: #e5e5e5;
  height: 100%;
  padding: 0 30px;
  .header {
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }
  .main {
    background: #fff;
    overflow: auto;
    padding: 0;
    .content {
      min-width: 992px;
      max-width: 1400px;
      margin: 0 auto 0;
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: justify;
      color: #616161;
    }
  }
}
</style>
