var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c("span", { staticClass: "title" }, [_vm._v("排课详情")]),
      _c(
        "el-row",
        { staticClass: "box-line my-lesson" },
        [
          _vm._v(" 课程目录 "),
          _c("el-row", { staticClass: "lesson-num" }, [
            _c(
              "span",
              [
                _vm._v(" 总课次： "),
                _c("font", [_vm._v(_vm._s(_vm.classNum))]),
              ],
              1
            ),
            _c(
              "span",
              [
                _vm._v(" 总任务数： "),
                _c("font", [_vm._v(_vm._s(_vm.taskNum))]),
              ],
              1
            ),
          ]),
          _vm._l(_vm.taskData, function (item, topindex) {
            return _c(
              "el-collapse",
              {
                key: topindex,
                attrs: { accordion: "" },
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.activeNames,
                  callback: function ($$v) {
                    _vm.activeNames = $$v
                  },
                  expression: "activeNames",
                },
              },
              [
                _c(
                  "el-collapse-item",
                  { attrs: { name: topindex } },
                  [
                    _c(
                      "template",
                      { slot: "title" },
                      [
                        _vm._v(
                          " 第" +
                            _vm._s(
                              (_vm.pagination.currentPage - 1) * 10 +
                                Number(topindex + 1)
                            ) +
                            "课：" +
                            _vm._s(item.title) +
                            " "
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "20px" },
                            attrs: {
                              plain: "",
                              size: "small",
                              type: "primary",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.missedLessonManage(
                                  _vm.taskData[topindex]
                                )
                              },
                            },
                          },
                          [_vm._v("查询课件")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      {
                        staticStyle: { "padding-left": "20px" },
                        attrs: { reverse: _vm.reverse },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            attrs: {
                              data: _vm.taskList,
                              "tooltip-effect": "dark",
                            },
                            on: { "selection-change": _vm.handleTaskSelection },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                type: "selection",
                                width: "55",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                label: "序号",
                                width: "120",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [_vm._v(_vm._s(scope.$index + 1))]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                label: "任务标题",
                                width: "200",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "title-link",
                                            on: {
                                              click: function ($event) {
                                                return _vm.previewTask(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(scope.row.title))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                label: "类型",
                                width: "120",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          { staticClass: "title-link" },
                                          [_vm._v(_vm._s(scope.row.type))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                label: "操作学员",
                                width: "120",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-popover",
                                          {
                                            staticClass: "openquestion",
                                            attrs: {
                                              placement: "left",
                                              trigger: "click",
                                              width: "238",
                                            },
                                            on: { hide: _vm.hideMethod },
                                          },
                                          [
                                            _c(
                                              "ul",
                                              {
                                                staticClass: "stuList clearfix",
                                              },
                                              [
                                                _c(
                                                  "li",
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "stu-tit",
                                                      },
                                                      [_vm._v("全体")]
                                                    ),
                                                    _c("el-switch", {
                                                      staticClass: "stu-switch",
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.switchCheckAll(
                                                            scope.row,
                                                            _vm.switchAll,
                                                            scope.$index
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: _vm.switchAll,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.switchAll = $$v
                                                        },
                                                        expression: "switchAll",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._l(
                                                  scope.row.taskUserList,
                                                  function (value, index) {
                                                    return _c(
                                                      "li",
                                                      { key: index },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "stu-tit",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(value.name)
                                                            ),
                                                          ]
                                                        ),
                                                        _c("el-switch", {
                                                          staticClass:
                                                            "stu-switch",
                                                          attrs: {
                                                            "active-value": 1,
                                                            "inactive-value": 2,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.switchCheckItem(
                                                                value,
                                                                scope.$index,
                                                                index
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              value.status ===
                                                              "已启用"
                                                                ? 1
                                                                : 2,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                value,
                                                                "status === '已启用'?1:2",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "value.status === '已启用'?1:2",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                  "margin-top": "5px",
                                                },
                                                attrs: {
                                                  slot: "reference",
                                                  plain: "",
                                                  size: "small",
                                                  type: "primary",
                                                },
                                                slot: "reference",
                                              },
                                              [_vm._v("开放学员")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                label: "操作",
                                width: "120",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.type === "编程"
                                          ? _c(
                                              "el-popover",
                                              {
                                                staticClass: "previewquestion",
                                                attrs: {
                                                  placement: "left",
                                                  trigger: "click",
                                                  width: "238",
                                                },
                                                on: { hide: _vm.hideMethod },
                                              },
                                              [
                                                _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "stuList clearfix",
                                                  },
                                                  _vm._l(
                                                    _vm.questionArray,
                                                    function (value, index) {
                                                      return _c(
                                                        "li",
                                                        { key: index },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "stupreview-tit",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "dateFormat"
                                                                  )(
                                                                    value.start_time
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "el-button",
                                                            {
                                                              staticClass:
                                                                "stu-switch",
                                                              attrs: {
                                                                size: "small",
                                                                type: "primary",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.queryItem(
                                                                      value,
                                                                      scope.$index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("查看")]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                      "margin-top": "5px",
                                                    },
                                                    attrs: {
                                                      slot: "reference",
                                                      plain: "",
                                                      size: "small",
                                                      type: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.queryQuestion(
                                                          scope.$index
                                                        )
                                                      },
                                                    },
                                                    slot: "reference",
                                                  },
                                                  [_vm._v("查看题库")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        scope.row.type === "测试"
                                          ? _c(
                                              "el-popover",
                                              {
                                                staticClass: "previewquestion",
                                                attrs: {
                                                  placement: "left",
                                                  trigger: "click",
                                                  width: "238",
                                                },
                                              },
                                              [
                                                _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "stuList clearfix",
                                                  },
                                                  _vm._l(
                                                    _vm.questionArray,
                                                    function (value, index) {
                                                      return _c(
                                                        "li",
                                                        { key: index },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "stupreview-tit",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "dateFormat"
                                                                  )(
                                                                    value.start_time
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "el-button",
                                                            {
                                                              staticClass:
                                                                "stu-switch",
                                                              attrs: {
                                                                size: "small",
                                                                type: "primary",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.openmarkingDialog(
                                                                      value.id,
                                                                      scope.row
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("查看")]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                      "margin-top": "5px",
                                                    },
                                                    attrs: {
                                                      slot: "reference",
                                                      plain: "",
                                                      size: "small",
                                                      type: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.queryQuestion(
                                                          scope.$index
                                                        )
                                                      },
                                                    },
                                                    slot: "reference",
                                                  },
                                                  [_vm._v("审阅试卷")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        scope.row.type === "python"
                                          ? _c(
                                              "el-popover",
                                              {
                                                staticClass: "previewquestion",
                                                attrs: {
                                                  placement: "left",
                                                  trigger: "click",
                                                  width: "238",
                                                },
                                                on: { hide: _vm.hideMethod },
                                              },
                                              [
                                                _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "stuList clearfix",
                                                  },
                                                  _vm._l(
                                                    _vm.lessonList,
                                                    function (value, index) {
                                                      return _c(
                                                        "li",
                                                        { key: index },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "stupreview-tit",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "formatTime"
                                                                  )(
                                                                    value.start_time
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "el-button",
                                                            {
                                                              staticClass:
                                                                "stu-switch",
                                                              attrs: {
                                                                size: "small",
                                                                type: "primary",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.submitListManage(
                                                                      scope.row,
                                                                      value.id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("查看")]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "20px",
                                                      "margin-top": "5px",
                                                    },
                                                    attrs: {
                                                      slot: "reference",
                                                      plain: "",
                                                      size: "small",
                                                      type: "primary",
                                                    },
                                                    slot: "reference",
                                                  },
                                                  [_vm._v("查看提交")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        scope.row.type === "python"
                                          ? _c(
                                              "el-popover",
                                              {
                                                staticClass: "previewquestion",
                                                attrs: {
                                                  placement: "left",
                                                  trigger: "click",
                                                  width: "238",
                                                },
                                                on: { hide: _vm.hideMethod },
                                              },
                                              [
                                                _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "stuList clearfix",
                                                  },
                                                  _vm._l(
                                                    _vm.lessonList,
                                                    function (value, index) {
                                                      return _c(
                                                        "li",
                                                        { key: index },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "stupreview-tit",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "formatTime"
                                                                  )(
                                                                    value.start_time
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "el-button",
                                                            {
                                                              staticClass:
                                                                "stu-switch",
                                                              attrs: {
                                                                size: "small",
                                                                type: "primary",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.submitListManage(
                                                                      scope.row,
                                                                      value.id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("查看")]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "20px",
                                                      "margin-top": "5px",
                                                    },
                                                    attrs: {
                                                      slot: "reference",
                                                      plain: "",
                                                      size: "small",
                                                      type: "primary",
                                                    },
                                                    slot: "reference",
                                                  },
                                                  [_vm._v("查看提交")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-popover",
                          {
                            ref: "popover4",
                            refInFor: true,
                            attrs: {
                              placement: "right",
                              trigger: "click",
                              width: "400",
                            },
                          },
                          [
                            _c(
                              "el-table",
                              {
                                ref: "multipleTable",
                                refInFor: true,
                                staticStyle: {
                                  width: "100%",
                                  height: "350px",
                                  "overflow-y": "auto",
                                },
                                attrs: {
                                  data: _vm.taskUserList,
                                  "tooltip-effect": "dark",
                                },
                                on: { "selection-change": _vm.handleSelection },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: { type: "selection", width: "55" },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "学号",
                                    prop: "loginName",
                                    width: "120",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "姓名",
                                    prop: "name",
                                    "show-overflow-tooltip": "",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-top": "10px" },
                                on: {
                                  click: function ($event) {
                                    return _vm.mapTaskAndUser("已启用")
                                  },
                                },
                              },
                              [_vm._v("全部开放")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-top": "10px" },
                                on: {
                                  click: function ($event) {
                                    return _vm.mapTaskAndUser("已禁用")
                                  },
                                },
                              },
                              [_vm._v("全部关闭")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-top": "20px" },
                                attrs: {
                                  slot: "reference",
                                  size: "medium",
                                  type: "info",
                                },
                                slot: "reference",
                              },
                              [_vm._v("批量开放")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            )
          }),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.pagination.currentPage,
              "page-sizes": _vm.pagination.pageSizes,
              "page-size": _vm.pagination.pageSize,
              layout: _vm.pagination.layout,
              total: _vm.pagination.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
          _c("missed-lesson", {
            attrs: {
              applyCourseData: _vm.missLessonCourseData,
              show: _vm.missLessonCourseFlag,
            },
            on: {
              close: function ($event) {
                _vm.missLessonCourseFlag = false
              },
            },
          }),
          _c("priview-file", {
            attrs: { show: _vm.priviewFileDialog },
            on: {
              close: function ($event) {
                _vm.priviewFileDialog = false
              },
            },
          }),
          _c("marking-exampaper", {
            attrs: {
              class_id: _vm.class_id,
              lessonId: _vm.lessonId,
              show: _vm.markingDialog,
              taskID: _vm.taskID,
            },
            on: {
              close: function ($event) {
                _vm.markingDialog = false
              },
            },
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }